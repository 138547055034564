<template>
	<div class="h-full flex flex-col">
		<TopBar :showHome="true" :showChat="true"/>
		<ProgressBar :current="3"/>
		<AppTitle :title="tableName" v-if="table"/>
		<div class="pt-2 text-gray-400 overflow-y-scroll flex-grow" v-if="table">
			<p>Select how far you are willing to travel to the restaurant</p>
			<div class="mt-6 relative">
				<div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
					<font-awesome-icon icon="fa-solid fa-magnifying-glass" class="pl-2 text-gray-400"/>
				</div>
				<input type="text" ref="search" @focus="error.address=false" v-model="search" class="block w-full pl-12 py-5 pr-4 rounded-3xl border border-gray-100 placeholder-gray-400 text-gray-500 focus:ring-red-500 focus:border-red-500" :class="{
					'border-red-500': error.address
				}" :placeholder="placeholder">
			</div>

			<div id="map" class="w-full rounded-xl mt-4"></div>

			<div class="mt-4">
				<p class="text-gray-600 font-bold text-lg">Distance: {{ distance_human }}</p>
				<div class="relative pt-1">
					<div class="w-full">
						<input type="range" v-model="distance" @change="drawMarker()" step="1" min="1" max="20" value="5" class="w-full h-1 bg-gray-200 appearance-none"/>
					</div>
				</div>
				<div class="flex mt-2">
					<div class="flex-1">1 mi</div>
					<div>No preference</div>
				</div>
			</div>

		</div>

		<div class="w-full pt-10 pb-6 bg-gray-50">
			<div>
				<button class="bg-red-500 text-white py-2 rounded-xl w-full font-gothic text-lg" @click="next">
					Next
				</button>
				<button class="text-gray-500 py-2 rounded-xl w-full font-gothic text-lg mt-4" @click="previous">
					Back
				</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import TopBar from '@/components/TopBar.vue';
	import ProgressBar from '@/components/ProgressBar.vue';
	import AppTitle from '@/components/AppTitle.vue';
	export default {
		components: {
			TopBar,
			AppTitle,
			ProgressBar,
		},

		data(){
			return {
				google: null,
				map: null,
				marker: null,
				autocomplete: null,
				circle: null,
				search: '',
				error: {
					address: false,
					distance: false
				},
				loadingUserLocation: false,
				map_icon: "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/geocode-71.png"
			}
		},
		mounted(){
			/*global google*/
			this.google = google;
			this.initAutocomplete();
			if (this.address){
				this.search = this.address.formatted_address;
				setTimeout( () => {
					this.drawMarker();
				}, 200);
			}
		},
		computed: {  
			tableName(){
				return this.table.name.length ? this.table.name : `Table ${this.table.code.toUpperCase()}`
			},
			table(){
				return this.$store.state.table.table;
			},
			placeholder(){
				return this.loadingUserLocation 
				? "Finding your location..."
				: this.userLocation 
				? "My current location" 
				: "Find a place";
			},
			distance: {
				get(){
					return this.table.answers.distance;
				},
				set(value){
					this.$store.commit('table/SET_ANSWER', {
						name: 'distance',
						value: value
					});
				}
			},
			address: {
				get(){
					return this.table.answers.address;
				},
				set(value){
					this.$store.commit('table/SET_ANSWER', {
						name: 'address',
						value: value
					});
				}
			},
			userLocation: {
				get(){
					return this.$store.state.table.userLocation;
				},
				set(value){
					this.$store.commit('table/SET_USER_LOCATION', value);
				}
			},
			distance_human(){
				return this.distance < 20 ? this.distance + " mi" : "No preference"
			},
			noDistancePreference(){
				return this.distance >= 20;
			}
		},
		methods: {
			getUserLocation() {
				this.search = '';
				if (navigator.geolocation) {
					this.loadingUserLocation = true;
					navigator.geolocation.getCurrentPosition(
						(position) => {

							this.userLocation = {
								lat: position.coords.latitude,
								lng: position.coords.longitude,
							};

							this.address = "myLocation";
							this.$store.commit('table/SET_ANSWER', {
								name: 'geo_lat',
								value: position.coords.latitude
							});
							this.$store.commit('table/SET_ANSWER', {
								name: 'geo_lon',
								value: position.coords.longitude
							});

							const icon = {
								url: this.map_icon,
								size: new this.google.maps.Size(71, 71),
								origin: new this.google.maps.Point(0, 0),
								anchor: new this.google.maps.Point(17, 34),
								scaledSize: new this.google.maps.Size(25, 25),
							};
							if (this.marker){
								this.marker.setMap(null)
							}
							this.marker = null;
							this.marker = new this.google.maps.Marker({
								map: this.map,
								icon,
								position: this.userLocation,
							});

							if (this.circle){
								this.circle.setMap(null)
							}
							this.circle = null;
							if (this.distance>0){
								this.circle = new this.google.maps.Circle({
									map: this.map,
									radius: this.milesToMeters(this.distance),
									strokeColor: "#dc5c51",
									strokeOpacity: 0.8,
									strokeWeight: 2,
									fillColor: "#dc5c51",
									fillOpacity: 0.35,
									center: this.userLocation,
								});
							}

							this.map.setCenter(this.userLocation);
							this.map.setZoom(12);
							this.loadingUserLocation = false;
						},
						() => {
							this.handleLocationError(true);
						}
						);
				} else {
					this.handleLocationError(false);
				}
			},
			handleLocationError(browserHasGeolocation) {
				this.userLocation = null;
				this.loadingUserLocation = false;
				console.error(
					browserHasGeolocation
					? "Error: The Geolocation service failed."
					: "Error: Your browser doesn't support geolocation."
					);
			},

			isValid(){
				let ret = true;
				if (!this.address && !this.userLocation){
					this.error.address = true;
					ret = false;
				}
				return ret;
			},
			next(){
				if (!this.isValid()) return;
				this.$store.dispatch('table/saveAnswers', {
					step: 4
				});
			},
			previous(){
				this.$store.dispatch('table/saveAnswers', {
					step: 2
				});
			},
			drawMarker(){

				const icon = {
					url: this.map_icon,
					size: new this.google.maps.Size(71, 71),
					origin: new this.google.maps.Point(0, 0),
					anchor: new this.google.maps.Point(17, 34),
					scaledSize: new this.google.maps.Size(25, 25),
				};
				if (this.marker){
					this.marker.setMap(null)
				}
				this.marker = null;
				this.marker = new this.google.maps.Marker({
					map: this.map,
					icon,
					position: this.userLocation ? this.userLocation : this.address.geometry.location,
				});

				if (this.circle){
					this.circle.setMap(null)
				}
				if (!this.noDistancePreference){
					this.circle = null;
					if (this.distance>0){
						this.circle = new this.google.maps.Circle({
							map: this.map,
							radius: this.milesToMeters(this.distance),
							strokeColor: "#dc5c51",
							strokeOpacity: 0.8,
							strokeWeight: 2,
							fillColor: "#dc5c51",
							fillOpacity: 0.35,
							center: this.userLocation ? this.userLocation : this.address.geometry.location
						});
					}
				}

				if (!this.userLocation){
					const bounds = new this.google.maps.LatLngBounds();
					if (this.address.geometry.viewport) {
						bounds.union(this.address.geometry.viewport);
					} else {
						bounds.extend(this.address.geometry.location);
					}
					this.map.fitBounds(bounds);
				}

				let zoom = 12;
				if (this.distance > 2){
					zoom = 11;
				}
				if (this.distance > 5){
					zoom = 10;
				}
				if (this.distance > 10){
					zoom = 9;
				}
				if (this.noDistancePreference){
					zoom = 14;
				}
				this.map.setZoom(zoom);

			},
			milesToMeters(miles){
				return miles * 1609.34;
			},
			initAutocomplete(){
				this.map = new this.google.maps.Map(document.getElementById("map"), {
					center: { lat: 25.7762, lng: -80.19 },
					zoom: 10,
					mapTypeId: "roadmap",
					disableDefaultUI: true,
					zoomControl: true,
				});

				const searchBox = new this.google.maps.places.SearchBox(this.$refs['search']);

				this.map.addListener("bounds_changed", () => {
					searchBox.setBounds(this.map.getBounds());
				});

				searchBox.addListener("places_changed", () => {

					const places = searchBox.getPlaces();

					if (places.length == 0) {
						return;
					}

					let place = places[0];
					if (!place.geometry || !place.geometry.location) {
						console.log("Returned place contains no geometry");
						return;
					}

					this.address = place;
					this.$store.commit('table/SET_ANSWER', {
						name: 'geo_lat',
						value: place.geometry.location.lat()
					});
					this.$store.commit('table/SET_ANSWER', {
						name: 'geo_lon',
						value: place.geometry.location.lng()
					});
					this.userLocation = null;
					this.drawMarker();

				});
			},
		}
	}
</script>

<style type="text/css" scoped>

	#map {
		height: 256px;
		width: 100%;
	}

	/* source: https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/ */
	input[type=range] {
		-webkit-appearance: none; /* Hides the slider so that custom slider can be made */
		width: 100%; /* Specific width is required for Firefox. */
		background: transparent; /* Otherwise white in Chrome */
	}

	input[type=range]::-webkit-slider-thumb {
		-webkit-appearance: none;
	}

	input[type=range]:focus {
		outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
	}

	input[type=range]::-ms-track {
		width: 100%;
		cursor: pointer;

		/* Hides the slider so custom styles can be added */
		background: transparent; 
		border-color: transparent;
		color: transparent;
	}

	/* Special styling for WebKit/Blink */
	input[type=range]::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 20px;
		width: 20px;
		border-radius: 1rem;
		background: #EF0A0A;
		margin-top: -8px;
		cursor: pointer;
	}

	/* All the same stuff for Firefox */
	input[type=range]::-moz-range-thumb {
		height: 16px;
		width: 16px;
		border-radius: 1rem;
		background: #EF0A0A;
		cursor: pointer;
	}

	/* All the same stuff for IE */
	input[type=range]::-ms-thumb {
		height: 16px;
		width: 16px;
		border-radius: 1rem;
		background: #EF0A0A;
		cursor: pointer;
	}

	input[type=range]::-webkit-slider-runnable-track {
		width: 100%;
		height: 4px;
		cursor: pointer;
		background: #dddddd;
		margin-top: 4px;
	}

	input[type=range]:focus::-webkit-slider-runnable-track {
		background: #cccccc;
	}

	input[type=range]::-moz-range-track {
		width: 100%;
		height: 4px;
		cursor: pointer;
		background: #dddddd;
	}

	input[type=range]::-ms-track {
		width: 100%;
		height: 4px;
		cursor: pointer;
		background: transparent;
		color: transparent;
	}
	input[type=range]::-ms-fill-lower {
		background: #2a6495;
	}
	input[type=range]:focus::-ms-fill-lower {
		background: #dddddd;
	}
	input[type=range]::-ms-fill-upper {
		background: #dddddd;
	}
	input[type=range]:focus::-ms-fill-upper {
		background: #367ebd;
	}
</style>