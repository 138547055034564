<template>
	<div class="pb-4">
		<div class="h-1 grid gap-1" :class="`grid-cols-${steps}`">
			<div 
				class="h-1 w-full"
				:class="step<=current ? 'bg-red-500' : 'bg-gray-200'"
				v-for="step in steps"
				:key="step"
			>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			current: {
				type: Number,
				required: true
			},
			steps: {
				type: Number,
				default: 5
			}
		},
		data(){
			return {

			}
		}
	}
</script>